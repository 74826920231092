import React, { Component } from "react";
import gcp from '../../assests//images/gcp.jpeg';

export default class DataScienceImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <img src={gcp} width={400}></img>
    );
  }
}
