import React, { Component } from "react";
import seniorExpo from '../../assests//images/ai_network.png';

export default class CloudInfraImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <img src={seniorExpo} width={400} height={200}></img>
    );
  }
}
